@tailwind base;
@tailwind components;
@tailwind utilities;

/* font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

.poppins-light {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.poppins-regular {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.poppins-medium {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* background header */
.bg-hero {
  background-color: #ffffff;
  background-image:  linear-gradient(rgba(78, 77, 77, 0.1) 1.8px, transparent 1.8px), linear-gradient(to right, rgba(78, 77, 77, 0.1) 1.8px, #ffffff 1.8px);
  background-size: 36px 36px;
}

/* chat bubble design css */
.speech-bubble {
	position: relative;
	background: #4DAAF8;
	border-radius: .4em;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 8px solid transparent;
	border-top-color: #4DAAF8;
	border-bottom: 0;
	margin-left: -8px;
	margin-bottom: -8px;
}

.bg-our-works {
  background-color: #FCFBFB;
}

.backdrop-filter-menu {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

/* PHONE VERSION */
@media only screen and (min-width: 320px) {
  .fontHeroSize {
    font-size: min(max(40px, 4.9vw), 80px);
    line-height: 10vw;
  }
}

@media only screen and (min-width: 520px) {
  .fontHeroSize {
    font-size: min(max(40px, 8vw), 80px);
    line-height: 8vw;
  }
}

/* TABLET VERSION */
@media only screen and (min-width: 768px) {
  .fontHeroSize {
    font-size: min(max(40px, 7vw), 80px);
    line-height: 7vw;
  }
}

/* TABLET VERSION */
@media only screen and (min-width: 868px) {
  .fontHeroSize {
    font-size: min(max(40px, 6.5vw), 80px);
    line-height: 7vw;
  }
}

/* DESKTOP VERSION */
@media only screen and (min-width: 980px) {
  .fontHeroSize {
    font-size: min(max(40px, 4.9vw), 80px);
    line-height: 5vw;
  }
}

/* LARGE DESKTOP VERSION */
@media only screen and (min-width: 1025px) {
  .fontHeroSize {
    font-size: 67px;
    line-height: 4.5rem;
  }
}
